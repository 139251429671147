import { App } from '@/types/App';
import { atomWithWorkerStorage } from './atomWithWorkerStorage';
import { APP_STATES, APP_STORAGE_KEY } from '@/constants/app';
import { SERVICE_COMPLETION_STATUS } from '@/constants/surveyService';

const initialValue: App = {
  state: APP_STATES.IDLE,
  move_task_order: {
    id: '',
    milmove_move_code: '',
    orders: [],
  },
  customer: {
    id: '',
    firstName: '',
    lastName: '',
  },
  service: {
    id: '',
    status: '',
    completionStatus: SERVICE_COMPLETION_STATUS.NOT_STARTED,
  },
  survey: {
    completed: false,
    items: [],
    moveTaskOrderId: '',
    orderId: '',
    rooms: [],
    segments: [],
    serviceId: '',
    surveyType: '',
  },
};

export const appAtom = atomWithWorkerStorage<App>(
  APP_STORAGE_KEY,
  initialValue
);
