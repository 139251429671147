import { BASE_SEGMENT } from '@/constants';
import { Survey } from '@/types';
import { createSegment } from './segment';

interface CreateEmptySurveyProps {
  orderId: string;
  serviceId: string;
  surveyType: string;
  moveTaskOrderId: string;
}

export const createEmptySurvey = ({
  orderId,
  serviceId,
  surveyType,
  moveTaskOrderId,
}: CreateEmptySurveyProps): Survey => ({
  orderId,
  serviceId,
  surveyType,
  moveTaskOrderId,
  completed: false,
  items: [],
  rooms: [],
  segments: [createSegment(BASE_SEGMENT)],
});
