import { CardContent, Stack } from '@mui/material';
import {
  getSegment,
  createSegment,
  removeSegment,
  updateSegment,
  removeItemsInSegment,
} from '@/utils';
import { SegmentsList } from './SegmentsList';
import { AddSegmentButton } from './AddSegmentButton';
import { Segment, SegmentData } from '@/types';
import { OBJECT_METHOD } from '@/constants/objectMethods';
import { selectedSegmentIdAtom } from '@/store';
import { useMediaMatches, useSurvey, useSurveyReport } from '@/hooks';
import { useAtom } from 'jotai';

export function AddSegmentControl() {
  const { segments, setSegments, setItems } = useSurvey();
  const [selectedSegmentId, setSelectedSegmentId] = useAtom(
    selectedSegmentIdAtom
  );

  const handleAddSegment = (segment: SegmentData) => {
    const existing = getSegment(segments, segment);
    if (existing) {
      setSelectedSegmentId(existing.id);
    } else {
      const newSegment = createSegment(segment);
      setSegments((prevSegments) => [...prevSegments, newSegment]);
      setSelectedSegmentId(newSegment.id as string);
    }
  };

  const handleSegmentClicked = (segment: Segment) => {
    setSelectedSegmentId(segment.id);
  };

  const onEditSegment = (segment: Segment) => {
    setSegments((prevSegments) => updateSegment(prevSegments, segment));
  };

  const onRemoveSegment = (segment: Segment) => {
    setItems((prevItems) => removeItemsInSegment(prevItems, segment.id));

    const updatedSegments = removeSegment(segments, segment);
    setSegments(updatedSegments);

    const displayedSegments = updatedSegments.filter(
      (segment) => segment._method !== OBJECT_METHOD.DESTROY
    );
    setSelectedSegmentId(displayedSegments[0]?.id);
  };

  const surveyReport = useSurveyReport();

  const matches = useMediaMatches();

  return (
    <CardContent>
      <Stack direction="row" gap={2} flexWrap="wrap">
        <SegmentsList
          segments={segments}
          itemsTotal={surveyReport.segmentItemsTotal.going}
          currentSegmentId={selectedSegmentId}
          onSegmentClicked={handleSegmentClicked}
          onEditSegment={onEditSegment}
          onRemoveSegment={onRemoveSegment}
        />

        <AddSegmentButton onAddSegment={handleAddSegment}>
          {matches.xs ? 'Segment' : 'New Segment'}
        </AddSegmentButton>
      </Stack>
    </CardContent>
  );
}
